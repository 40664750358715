
import sportsIcon from "../assets/icons/icon-sport.svg";
import slotIcon from "../assets/icons/icon-slot.svg";
import TableIcon from "../assets/icons/icon-table.svg";
import fishicon from "../assets/icons/icon-fish.svg";
import arcadeIcon from "../assets/icons/icon-arcade.svg";
import lotteryIcon from "../assets/icons/icon-lottery.svg";
import withdrawIcon from "../assets/icons/icon-withdrawal.svg";
import DepositIcon from "../assets/icons/icon-deposit.svg";
import liveCasinoIcon from "../assets/icons/icon-casino.svg";

import liveCasino1 from "../assets/imagesNew/live_casino1.webp";
import liveCasino2 from "../assets/imagesNew/live_casino2.webp";
import bannerSlider1 from "../assets/imagesNew/banner-slider1.jpg";
import bannerSlider2 from "../assets/imagesNew/banner-slider2.jpg";

import jilliIcon from "../assets/imagesNew/jilli.webp";
import Jili from "../assets/imagesNew/jilii.png";
import esport from "../assets/images/esport.webp";
import cashRocket from "../assets/images/cash-rocket.webp";
import sevenupsevendown from "../assets/images/7up7down.webp";
import sicbo from "../assets/images/sicbo.webp";
import cardmatka from "../assets/images/cardmatka.webp";
import feature1 from "../assets/images/feature-1.jpg";
import feature2 from "../assets/images/feature-2.jpg";
import feature3 from "../assets/images/feature-3.jpg";
import feature4 from "../assets/images/feature-4.jpg";
import feature5 from "../assets/images/feature-5.jpg";
import feature6 from "../assets/images/feature-6.jpg";
import feature7 from "../assets/images/feature-7.jpg";
import feature8 from "../assets/images/feature-7.jpg";
import hotIcon from "../assets/images/icon-star.svg";

import dragonsoft from "../assets/images/DRAGONSOFT.webp";
import biggaming from "../assets/images/BIGGAMING.webp";
import betgames from "../assets/images/BETGAMES.webp";
import sv388 from "../assets/images/SV388.webp";
import sabavrtual from "../assets/images/SABAVIRTUAL.webp";


import iconExchange from "../assets/images/icon-exchange.svg";
import iconSportbook from "../assets/images/icon-sportbook.svg";
import iconSboSport from "../assets/images/icon-sbov2.svg";
import iconHorsebook from "../assets/images/icon-horsebook.svg";
import iconVirtual from "../assets/images/icon-virtual.svg";
import iconKabaddi from "../assets/images/icon-kabaddi.svg";
import velkieLogo from "../assets/images/logo-velki.png";

import fc from "../assets/images/fc.png";
import jdb from "../assets/images/jdb.png";
import km from "../assets/images/km.png";
import ludo from "../assets/images/ludo.png";
import pg from "../assets/images/pg.png";
import pt from "../assets/images/pt.png";
import ps from "../assets/images/provider-ps.png";
import rt from "../assets/images/rt.png";
import sexy from "../assets/images/sexy.png";
import sg from "../assets/images/sg.png";
import venus from "../assets/images/venus.png";
import evo from "../assets/images/evo.png";
import sv from "../assets/images/sv.webp";
import pp from "../assets/images/pp.png";
import yl from "../assets/images/yl.png";
import yesbingo from "../assets/images/provider-awcmyesbingo.png";
import rich88 from "../assets/images/provider-rich88.png";
import ka from "../assets/images/provider-ka.png";
import cq9 from "../assets/images/provider-cq9.png";
import netent from "../assets/images/provider-netent.png";
import worldmatch from "../assets/images/provider-worldmatch.png";
import fastspinn from "../assets/images/provider-awcmfastspin.png";
import joker from "../assets/images/provider-joker.png";
import p8 from "../assets/images/provider-awcmp8.png";
import png from "../assets/images/provider-playngo.png";
import spribe from "../assets/images/provider-jdbaspribe.png";
import bpoker from "../assets/images/provider-bpoker.png";
import number from "../assets/images/provider-number.png";


import evoNew from "../assets/images/evolution.webp";
import ezugiNew from "../assets/images/evolution-new.webp";
import jiliNew from "../assets/images/jililobby.webp";
import pragmaticNew from "../assets/images/pragmatic-play.webp";
import aeSexy from "../assets/images/aeSexy.webp";
import horseRacing from "../assets/images/horse-racing.gif";
import ptNew from "../assets/images/ptNew.jpg";
import bgNew from "../assets/images/BigG.jpg";



import pakistanFlag from "../assets/images/flag-symbolism-Pakistan-design-Islamic.png";
import camodiaFlag from "../assets/images/Flag-Cambodia.png";
import philippinesFlag from "../assets/images/Flag_of_philippines.svg.jpg";
import brazilFlag from "../assets/images/Flag_of_Brazil.svg.png";
import indiaFlag from "../assets/images/Flag_of_India.svg.png";
import bangladeshFlag from "../assets/images/Flag_of_Bangladesh.svg.png";
// hotgame Image
import pmegasicbo from "../assets/images/hot-casino/pmegasicbo.webp";
import pmegawheel from "../assets/images/hot-casino/pmegawheel.webp";
import pmegaroll from "../assets/images/hot-casino/pmegaroll.webp";
import pbaccarat from "../assets/images/hot-casino/pbaccarat.webp";
import pdt from "../assets/images/hot-casino/pdt.webp";
import ppsicbo from "../assets/images/hot-casino/ppsicbo.webp";
import pthilo from "../assets/images/hot-casino/pthilo.webp";
import ptspinwin from "../assets/images/hot-casino/ptspinwin.webp";
import playtechteenpatti from "../assets/images/hot-casino/playtech-teen-patti.webp";
import bgroll from "../assets/images/hot-casino/bgroll.webp";
import bgsicbo from "../assets/images/hot-casino/bgsicbo.webp";
import bgspeedsicbo from "../assets/images/hot-casino/bgspeedsicbo.webp";
import pcrazytime from "../assets/images/hot-casino/pcrazytime.webp";
import pmonopoly from "../assets/images/hot-casino/pmonopoly.webp";
import pcoinflip from "../assets/images/hot-casino/pcoinflip.webp";
import evoteenpatti from "../assets/images/hot-casino/evoteenpatti.webp";
import evosupersicbo from "../assets/images/hot-casino/evosupersicbo.webp";
import evospeedroullete from "../assets/images/hot-casino/evospeedroullete.webp";
import evolightdice from "../assets/images/hot-casino/evolightdice.webp";
import evoligtroullete from "../assets/images/hot-casino/evoligtroullete.webp";
import ppbacarrat2 from "../assets/images/hot-casino/ppbacarrat2.webp";

import hotgame028 from "../assets/images/hot-casino/KM-TABLE-028.webp";
import hotgame035 from "../assets/images/hot-casino/JILI-TABLE-035.webp";
import hotgame030 from "../assets/images/hot-casino/JILI-TABLE-030.webp";
import hotgame060 from "../assets/images/hot-casino/KM-TABLE-060.webp";

import hotgame047 from "../assets/images/hot-casino/JILI-TABLE-047.webp";
import hotgame015 from "../assets/images/hot-casino/KM-TABLE-015.webp";
import hotgame021 from "../assets/images/hot-casino/KM-TABLE-021.webp";
import hotgame022 from "../assets/images/hot-casino/KM-TABLE-022.webp";
import pgdragon from "../assets/images/hot-casino/pgdragon.webp";


import HomeIcon from "../assets/icons/icon-home.svg";
import exchangeIcon from "../assets/icons/icon-cricket.svg";
import referralIcon from "../assets/icons/icon-referral.svg";
import promotionIcon from "../assets/icons/icon-promotion.svg";

import helpers from "../Utils/helpers";

const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;
export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};

export const CountryFlagBanner = [
  { id: 1, banner: pakistanFlag,title:"Pakistan" },
  { id: 2, banner: camodiaFlag ,title:"camodia"},
  { id: 3, banner: philippinesFlag ,title:"philippines"},
  { id: 4, banner: brazilFlag ,title:"brazil"},
  { id: 5, banner: indiaFlag,title:"india" },
  { id: 6, banner: bangladeshFlag ,title:"bangladesh"},
];
export const LiveCasinoData = [
  {
    id: 1,
    icons: liveCasino2,
    title: "AE SEXY",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-002",
  },
  {
    id: 2,
    icons: liveCasino1,
    title: "WE",
    titlebn: "আমরা",
    platForm: "1006",
    gameType: "egtGame",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },

  {
    icons: ezugiNew,
    title: "Ezugi",
    titlebn: "এজুগি",
    type: "ezugi",
    key: "live",
    gameType: "egtGame",
    platForm: "203",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },
  {
    icons: bgNew,
    title: "BG",
    titlebn: "বি.জি",
    type: "bg",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "BG",
  },
  {
    icons: horseRacing,
    title: "HOUSE BOOK",
    titlebn: "হাউস বুক",
    type: "housebook",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "HORSEBOOK",
  },
  {
    icons: jiliNew,
    title: "JILI",
    titlebn: "জিলি",
    type: "jili",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "JILI",
  },
  {
    icons: pragmaticNew,
    title: "PP",
    titlebn: "পিপি",
    type: "pp",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "PP",
  },
  {
    icons: ptNew,
    title: "PT",
    titlebn: "পিটি",
    type: "pt",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "PT",
  },
  {
    icons: evoNew,
    title: "Evo",
    titlebn: "ইভো",
    type: "evo",
    key: "live",
    gameType: "egtGame",
    platForm: "1006",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },
  {
    icons: aeSexy,
    title: "SEXY",
    titlebn: "সেক্সি",
    type: "sexy",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
  },
  {
    icons: sv,
    title: "SV388",
    titlebn: "এসভি৩৮৮",
    type: "sv",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SV388",
  },
  {
    icons: venus,
    title: "VENUS",
    titlebn: "শুক্র",
    type: "venus",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "VENUS",
  },
];
export const BannerSliderData = [
  { id: 1, bannerImg: bannerSlider1 },
  { id: 2, bannerImg: bannerSlider2 },
];
export const SlotData = [
  {
    id: 1,
    icons: feature1,
    title: "Wild Bounty Showdown",
    titlebn: "ফাস্টস্পিন",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "FASTSPIN",
    casinoType:"FastSpin-FISH-001"
  },
  {
    id: 2,
    icons: feature2,
    title: "Treasures of Aztec",
    titlebn: "জেডিবি",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JDB",
  },
  {
    id: 3,
    icons: feature3,
    title: "Elemental Link Fire",
    titlebn: "জিলি",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
  },
  {
    id: 4,
    icons: feature4,
    title: "SuperNiubi",
    titlebn: "পৃ",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "PP",
  },
  {
    id: 5,
    icons: feature5,
    title: "Supermarket Spree",
    titlebn: "কোদাল",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "SPADE",
  },{
    id: 6,
    icons: feature6,
    title: "Cocktail Nights",
    titlebn: "কোদাল",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "SPADE",
  },{
    id: 5,
    icons: feature7,
    title: "Asgardian Rising",
    titlebn: "কোদাল",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "SPADE",
  }
];

export const DepositWithrawDataNew = [
  {
    id: 1,
    icons: DepositIcon,
    title: "Deposit",
    link: "/deposit",
    titlebn: "জমা",
  },
  {
    id: 2,
    icons: withdrawIcon,
    title: "Withdraw",
    link: "/withdraw",
    titlebn: "প্রত্যাহার করুন",
  },
];
export const DepositWithrawData = [
  {
    id: 1,
    icons: hotIcon,
    title: "Hot",
    type: "hotgame",
    titlebn: "গরম খেল",
  },
  {
    id: 1,
    icons: sportsIcon,
    title: "Sports",
    type: "sports",
    titlebn: "বিনিময়",
  },
  {
    id: 2,
    icons: liveCasinoIcon,
    title: "Live Casino",
    titlebn: "লাইভ ক্যাসিনো",
    type: "casino",
  },
  { id: 3, 
    icons: slotIcon,
     title: "Slot",
      titlebn: "স্লট",
       type: "slot" 
      },
  { id: 4, 
    icons:TableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table" 
  },
  {
    id: 5,
    icons: fishicon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
  },
  {
    id: 5,
    icons: arcadeIcon,
    title: "Arcade",
    type: "arcade",
    titlebn: "স্পোর্টসবুক",
  },
  
  {
    id: 6,
    icons: lotteryIcon,
    title: "Lottery",
    titlebn: "খেলাধুলা",
    type: "lottery",
  },
  
];
export const DepositWithrawDataArray = [
  {
    id: 1,
    title: "Hot",
    key: "hotgame",
    titlebn: "গরম খেল",
    array: [
      
      {
        id: 1,
        image: hotgame030,
        title: "Go Rush",
        type: "Go Rush",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-030",
      },
      {
        id: 2,
        image: hotgame047,
        title: "Crash Bonus",
        type: "Crash Bonus",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-047",
      },
      {
        id: 3,
        image: hotgame035,
        title: "Limbo",
        type: "Limbo",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-035",
      },
      {
        id: 4,
        image: hotgame021,
        title: "Sugar Blast",
        type: "Sugar Blast",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "SLOT",
        casinoType: "KM-SLOT-001",
      },
      {
        id: 5,
        image: hotgame015,
        title: "Sicbo",
        type: "Sicbo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-015",
      },
    
      {
        id: 6,
        image: hotgame021,
        title: "Matka",
        type: "Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-021",
      },
      {
        id:7,
        image: hotgame022,
        title: "Card Matka",
        type: "Card Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-022",
      },
      {
        id: 8,
        image: hotgame028,
        title: "7 Up 7 Down",
        type: "7 Up 7 Down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },
      {
        id: 9,
        image: hotgame060,
        title: "Ludo",
        type: "Ludo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        id: 10,
        image: pgdragon,
        title: "Dragon ",
        type: "Dragon Tiger",
        gameTypeCheck: "platForm",
        platForm: "PG",
        gameType: "SLOT",
        casinoType: "PG-SLOT-035",
      },

    ],
  },
  {
    id: 1,
    title: "Sports",
    key: "sports",
    titlebn: "বিনিময়",
    array: [
      {
        image: iconExchange,
        title: "Exchange",
        gameTypeCheck: "sports",
        link: `${"https://bxawscf."+helpers?.getDomain()}/bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      // {
      //   image: iconExchange,
      //   title: "Exchange 2",
      //   gameTypeCheck: "sports",
      //   link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
      //     "token"
      //   )}`,
      // },
      {
        image: iconSportbook,
        title: "I-Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      // {
      //   image: iconSboSport,
      //   title: "SBO Sports",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      {
        image: iconHorsebook,
        title: "Horsebook",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      // {
      //   image: iconVirtual,
      //   title: "Virtual",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      // {
      //   image: iconKabaddi,
      //   title: "Kabaddi",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
    ],
  },
  {
    id: 2,
    title: "Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: evo,
        title: "Evo",
        type: "evo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbyTrue:true
      },
      {
        image: sexy,
        title: "SEXY",
        type: "sexy",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "",
        casinoType: "",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "",
        casinoType: "",
      },
      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PT",
      },
      
        {
          image: Jili,
          title: "JILI",
          type: "jili",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "JILI",
        },
        {
          image: km,
          title: "KINGMAKER",
          type: "kingmaker",
          key: "live",
          gameTypeCheck: "platForm",
          gameType: "TABLE",
          platForm: "KINGMAKER",
        }, 
        {
          image: ludo,
          title: "LUDO",
          type: "ludo",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "KINGMAKER",
          gameType: "TABLE",
          casinoType: "KM-TABLE-060",
        },
        {
          image: evo,
          title: "EVOLUTION",
          type: "evo",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "EVOLUTION",
          gameType: "",
          casinoType: "",
        },
        { image: betgames,
          title: "BETGAMES",
          type: "bg",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "BG",
          gameType: "LIVE",
          casinoType: "BG-LIVE-001",
        },
          {
          image: rt,
          title: "REDTIGER",
          type: "rt",
          key: "live",
          gameTypeCheck: "platForm",
          gameType: "LIVE",
          platForm: "RT",
        },
        {
          image: sg,
          title: "SPADEGAMING",
          type: "spade",
          key: "live",
          gameTypeCheck: "platForm",
          gameType: "live",
          platForm: "SPADE",
        },
        {
          image: fc,
          title: "FC",
          type: "fc",
          key: "live",
          gameTypeCheck: "platForm",
          gameType: "LIVE",
          platForm: "FC",
        },
        {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "JDB",
      },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "YL",
      },
  
      {
        image: dragonsoft,
        title: "DRAGONSOFT",
        type: "dragonsoft",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "DRAGONSOFT",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-001",
      },
      {
        image: pt,
        title: "PLAYTECH",
        type: "pt",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PT",
      },
    
      {
        image: yesbingo,
        title: "YESBINGO",
        type: "yesbingo",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "YESBINGO",
      },
        {
          image: sexy,
          title: "SEXYBCRT",
          type: "sexy",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "SEXYBCRT",
          gameType: "LIVE",
          casinoType: "MX-LIVE-002",
        },
      
        {
          image: esport,
          title: "E1SPORT",
          type: "e1sports",
          key: "live",
          gameTypeCheck: "platForm",
          gameType: "ESPORTS",
          platForm: "E1SPORT",
          casinoType:"E1-ESPORTS-001"
        },
        {
          image: biggaming,
          title: "BIGGAMING",
          type: "biggaming",
          key: "live",
          gameTypeCheck: "platForm",
          gameType: "LIVE",
          platForm: "BG",
          casinoType:""
        },
        {
          image: pg,
          title: "PGSOFT",
          type: "pg",
          key: "slot",
          gameTypeCheck: "platForm",
          gameType: "SLOT",
          platForm: "PG",
        },
    
        {
          image: sv388,
          title: "SV388",
          type: "sv",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "SV388",
        },
  
        {
          image: sabavrtual,
          title: "SABAVIRTUAL",
          type: "SABAVIRTUAL",
          key: "live",
          gameTypeCheck: "platForm",
          platForm: "SABA",
        },
       
    {
      image: spribe,
      title: "SPRIBE",
      type: "SPRIBE",
      key: "live",
      gameTypeCheck: "platForm",
      platForm: "SPRIBE",
    },
    
      
    ],
  },
  {
    id: 2,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JDB",
      },
      // {
      //   image: pg,
      //   title: "PG",
      //   type: "pg",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PG",
      // },
      // {
      //   image: sg,
      //   title: "SG",
      //   type: "spade",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "SPADE",
      // },
      // {
      //   image: rt,
      //   title: "RT",
      //   type: "rt",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      // },
      // {
      //   image: fc,
      //   title: "FC",
      //   type: "fc",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      // },
    
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },
      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PT",
      },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      // },
      // {
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      //   casinoType: "FC-SLOT-001",
      // },
      // {
      //   image: netent,
      //   title: "NETENT",
      //   type: "NETENT",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      //   casinoType: "FC-SLOT-002",
      // },{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-001",
      // },
     
      // {
      //   image: fastspinn,
      //   title: "FastSpin",
      //   type: "FastSpin",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FASTSPIN",
      // },
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-002",
      // },
      // {
      //   image: p8,
      //   title: "P8",
      //   type: "P8",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-003",
      // },
      // {
      //   image: png,
      //   title: "PNG",
      //   type: "PNG",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-009",
        
      // },
      
      // {
      //   image: yesbingo,
      //   title: "Yes Bingo",
      //   type: "yesbingo",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "YESBINGO",
      // },
      // {
      //   image: yl,
      //   title: "YL",
      //   type: "yl",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "YL",
      // },
    ],
  },
  {
    id: 3,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      
      // {
      //   image: spribe,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      //   casinoType: "JILI-TABLE-003",
      // },
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "PG",
      },
      // {
      //   image: png,
      //   title: "PNG",
      //   type: "PNG",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      //   casinoType: "JILI-TABLE-004",
      // },{
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },   
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-004",
        
      // },{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-005",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-006",
      // },{
      //   image: bpoker,
      //   title: "Bpoker",
      //   type: "bpoker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      // },
      {
        image: ludo,
        title: "LUDO",
        type: "ludo",
        key: "table",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      // {
      //   image: sevenupsevendown,
      //   title: "7up7Down",
      //   type: "7up7down",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KM-TABLE-028",
      // },
      // {
      //   image: sicbo,
      //   title: "Sicbo",
      //   type: "sicbo",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KM-TABLE-015",
      // },
      // {
      //   image: cardmatka,
      //   title: "Matka",
      //   type: "matka",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KM-TABLE-022",
      // },
      
    ],
  },
  {
    id: 4,
    title: "Fishing",
    key: "fish",
    type: "fish",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "FC",
      },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "FC",
      //   casinoType:"FC-FISH-001"
        
      // },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JDB",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-010",
      // },
      // {
      //   image: p8,
      //   title: "P8",
      //   type: "P8",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YL",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      }
    ],
  },
  
  {
    id: 6,
    title: "Arcade",
    key: "arcade",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: esport,
        title: "E1sports",
        type: "e1sports",
        key: "esports",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "FC",
      },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: pp,
      //   title: "PP",
      //   type: "pp",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "FC",
      },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "YL",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "SPADE",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-011",
      // },
    ],
  },
  {
    id: 7,
    title: "Lottery",
    key: "lottery",
    titlebn: "খেলাধুলা",
    array: [
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },{
        image: number,
        title: "Money",
        type: "number",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
        casinoType:"JILI-SLOT-029"
      },{
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      }
      ,{
        image: cashRocket,
        title: "Rocket",
        type: "rocket",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType:"KM-TABLE-041"
      },

      
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      }
      
    ],
  },
];
export const HotCasinoData = [
 
  {
    id: 11,
    icons: pmegasicbo,
    title: "Mega Sic Bo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
  },
  {
    id: 12,
    icons: pmegawheel,
    title: "Mega Wheel",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
  },
  {
    id: 13,
    icons: pmegaroll,
    title: "Mega Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
  },
  {
    id: 14,
    icons: pbaccarat,
    title: "Mega Baccarat",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
  },
  {
    id: 15,
    icons: pdt,
    title: "Dragon Tiger",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
  },
  {
    id: 16,
    icons: ppbacarrat2,
    title: "Baccarat 2",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-002",
  },
  {
    id: 17,
    icons: ppsicbo,
    title: "SicBo Deluxe",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-003",
  },
  {
    id: 17,
    icons: pthilo,
    title: "Hi-Lo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
  },
  {
    id: 17,
    icons: ptspinwin,
    title: "Spin a Win",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
  },
  {
    id: 17,
    icons: playtechteenpatti,
    title: "Teen Patti Live",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-087",
  },

  {
    id: 18,
    icons: bgroll,
    title: "Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-003",
  },
  {
    id: 19,
    icons: bgsicbo,
    title: "Sicbo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-004",
  },
  {
    id: 20,
    icons: bgspeedsicbo,
    title: "SpeedSicBo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-012",
  },
  {
    id: 20,
    icons: evosupersicbo,
    title: "Super Sic Bo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
  },
  {
    id: 20,
    icons: pcrazytime,
    title: "Crazy Time",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    id: 20,
    icons: pmonopoly,
    title: "MONOPOLY",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
  },
  {
    id: 20,
    icons: evolightdice,
    title: "Lightning Dice",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
  },
  {
    id: 20,
    icons: pcoinflip,
    title: "Crazy Coin Flip",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
  },
  {
    id: 20,
    icons: evoteenpatti,
    title: "Teen Patti",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-026",
  },
  {
    id: 20,
    icons: evoligtroullete,
    title: "Lightning Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
  },
  {
    id: 20,
    icons: evospeedroullete,
    title: "Speed Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
  },


];
export const AuraCasinoData = [
  {
    image: "../assets/images/poker/aviator.webp",
    title: "Aviator",
    gameCode: "9999",
    legacy: "67722-1_8",
  },

  {
    image: "../assets/images/poker/avaitrix.webp",
    title: "Aviatrix",
    gameCode: "9999",
    legacy: "67722-2_8",
  },
  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
  gameCode: "87564",
  legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
];
export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  newPass: /^[a-zA-Z0-9]{8,15}$/,
  newPassMessage:
    "Password must contain uppercase and lowercase characters, numbers and must be minimum 8 character long (without special character).",
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const AmountArray = [
  { id: 1, amount: 100 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];
export const bottomNavigation = [
  {
    id: "1",
    menu: "Home",
    icon: HomeIcon,
    link: "/",
  },
  {
    id: "2",
    menu: "Exchange",
    icon: exchangeIcon,
    link: "/exchange",
  },
  {
    id: "3",
    menu: "Refer",
    icon: referralIcon,
    link: "/earn",
  },
  {
    id: "4",
    menu: "Casino",
    icon: liveCasinoIcon,
    link: "/casino",
  },
  {
    id: "5",
    menu: "Promotions",
    icon: promotionIcon,
    link: "/promotions",
  },
 
];
export const premiumCategoryArray = [
  { type: 1, name: "Popular" },
  { type: 3, name: "Match" },
  { type: 5, name: "Innings" },
  { type: 10, name: "Over" },
  { type: 2, name: "Batsman" },
  { type: 6, name: "Partnership" },
  { type: 7, name: "Group" },
  { type: 9, name: "Range" },
  { type: 11, name: "Head-to-Head" },
  { type: 8, name: "Player" },
];
export const categoryArray = [
  { type: 1, name: "Market" },
  { type: 3, name: "Bookmakers" },
  { type: 5, name: "ManualOdds" },
  { type: 10, name: "Win Toss" },
  { type: 2, name: "Session" },
  { type: 6, name: "Over by Over Session Market" },
  { type: 7, name: "Player" },
  { type: 9, name: "Wicket" },
  { type: 11, name: "Bowler Session" },
  { type: 8, name: "Last Digit ODD" },
  { type: 4, name: "LineMarket" },
  { type: 14, name: "Premium ODDs" },
];
export const gamesLiveArray = [
  
  {
    img: "../assets/images/game-shows/GAME-SHOW-1.webp",
    name: "XXXtreme Lightning roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-205",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-2.webp",
    name: "Cash or Crash",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-175",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-3.webp",
    name: "Funky Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-4.webp",
    name: "Dead or Alive Saloon",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-176",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-5.webp",
    name: "Crazy Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-6.webp",
    name: "Lightning Dice",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-7.webp",
    name: "Lightning Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-8.webp",
    name: "Lightning Fever",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-9.webp",
    name: "Extra Chilli",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-168",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-10.webp",
    name: "Imperial Quest",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-169",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-11.webp",
    name: "Monopoly Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-12.webp",
    name: "Monopoly Big Baller",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-018",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-13.webp",
    name: "Crazy Coin Flip",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-14.webp",
    name: "Mega Ball",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-010",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-15.webp",
    name: "Deal or Nodeal",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-16.webp",
    name: "Wounderland",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-17.webp",
    name: "Shweet Bonanza Candy Land",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-044",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-18.webp",
    name: "Spin and Win Wild A",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-19.webp",
    name: "Spin and Win Wild B X2",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-20.webp",
    name: "The Greatest Cards Show",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-21.webp",
    name: "Mega Wheel",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-22.webp",
    name: "Mega Fire Blaze Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-028",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-23.webp",
    name: "Mega Roulette 500x",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-24.webp",
    name: "Mega Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-25.webp",
    name: "Quantum Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-26.webp",
    name: "Power Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-055",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-27.webp",
    name: "Sicbo Delux",
    gameType: "LIVE",
    platForm: "PT",
    casinoType: "PT-LIVE-003",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-28.webp",
    name: "Mega Sicbo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-29.webp",
    name: "Bet on Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-030",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-30.webp",
    name: "Hi-lo Club",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-31.webp",
    name: "Quantum BlackJack Plus",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-029",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-32.webp",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-031",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/Baccarat/BACCARAT-1.webp",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-179",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-2.webp",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-038",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-3.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-137",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-4.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-138",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-5.webp",
    name: "Lightning Baccarat First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-133",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-6.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-149",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-7.webp",
    name: "Baccarat Classic",
    table:'1',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-8.webp",
    name: "Baccarat Classic",
    table:'2',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-9.webp",
    name: "Baccarat Classic",
    table:'3',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-10.webp",
    name: "Baccarat Classic",
    table:'4',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-11.webp",
    name: "Baccarat Classic",
    table:'5',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-12.webp",
    name: "Baccarat Classic",
    table:'6',
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-13.webp",
    name: "Bet on Baccarat Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-005",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-14.webp",
    name: "Baccarat 1",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-035",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-15.webp",
    name: "Baccarat 3",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-003",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-16.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-17.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-18.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-19.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-20.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-21.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-22.webp",
    name: "Baccarat Classic",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-001",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-24.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-25.webp",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-045",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-26.webp",
    name: "Speed Baccarat",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-002",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-27.webp",
    name: "Baccarat Insurance",
    platForm: "SEXY",
    gameType: "LIVE",
    casinoType: "MX-LIVE-002",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-28.webp",
    name: "Baccarat Insurance",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-003",
    cateogry: "VENUS",
    type: "BACCARAT",
  },
  // Baccarat End

  // Roulette Start
  {
    img: "../assets/images/Roulette/ROULETTE-1.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-2.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-3.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-4.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-5.webp",
    name: "Instant Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-008",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-6.webp",
    name: "Roulette First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-206",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-7.webp",
    name: "Amercian Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-206",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-8.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-9.webp",
    name: "Roulette",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    cateogry: "SEXY",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-10.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-032",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-11.webp",
    name: "Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-026",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-12.webp",
    name: "Speed Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-027",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/PP-LIVE-054.webp",
    name: "Speed Roulette 2",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-029",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-13.webp",
    name: "Roulette",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-009",
    cateogry: "VENUS",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-14.webp",
    name: "Speed Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-027",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-15.webp",
    name: "Azure Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-028",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-16.webp",
    name: "American Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-021",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-17.webp",
    name: "Deutsches Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-022",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-18.webp",
    name: "Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-025",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-19.webp",
    name: "Football French Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-20.webp",
    name: "Football Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-024",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-21.webp",
    name: "Spread Bets Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-027",
    cateogry: "PT",
    type: "ROULETTE",
  },

  {
    img: "../assets/images/Dice/DICE-1.webp",
    name: "Bac Bo Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-053",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-2.webp",
    name: "Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-050",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-3.webp",
    name: "Craps Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-003",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-4.webp",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-211",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-5.webp",
    name: "Extra Sic bo",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    cateogry: "SEXY",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-6.webp",
    name: "Red Blue Duel",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-010",
    cateogry: "SEXY",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-7.webp",
    name: "Sic Bo",
    platForm: "VENUS",
    gameType: "LIVE",
    casinoType: "VN-LIVE-007",
    cateogry: "VENUS",
    type: "DICE",
  },

  // Tradiotional Start
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
    name: "Super Andhar Bahar",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-204",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
    name: "Fan Tan",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-178",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-004",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-033",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
    name: "Teen Patti Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-087",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-033",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-033",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
    name: "Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-002",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
    name: "Dragon Tiger",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
    name: "Dragon Tiger",
    platForm: "VN",
    gameType: "LIVE",
    casinoType: "VN-LIVE-006",
    cateogry: "VENUS",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
    name: "Dragon Tiger",
    platForm: "VN",
    gameType: "LIVE",
    casinoType: "VN-LIVE-006",
    cateogry: "VENUS",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
    name: "Extra Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-012",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
    name: "Andhar Bahar Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-004",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
    name: "Andhar Bahar",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-043",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
    name: "Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
    name: "Teen Patti",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-026",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  // Tradiotional End

  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
    name: "Lightning Black Jack",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-181",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-207",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
    name: "Black Jack A",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-002",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
    name: "Casino Hold'em",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-015",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
    name: "Triple Card Poker",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
    name: "Infinite Black Jack",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-012",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
    name: "Caribbean Stud Poker",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-024",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-006.webp",
    name: "Alexandrite Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-007.webp",
    name: "All bets Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-040.webp",
    name: "One Blackjack",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-040",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-041.webp",
    name: "ONE Blackjack 2 - Ruby",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-041",
    cateogry: "PP",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-008.webp",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-008",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-010.webp",
    name: "Unlimited Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-010",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-016.webp",
    name: "3 Card Brag",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-016",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-017.webp",
    name: "Casino Hold AEM",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-017",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-019.webp",
    name: "Bet on Poker Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-019",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-009.webp",
    name: "Sette E Mezzo",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-009",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  // Table Start KINGMAKER (casino Done) -------
  {
    img: "../assets/images/Table/TABLE-2.webp",
    name: "Cash Rocket",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-3.webp",
    name: "Ludo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-060",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    name: "Marble Knockout",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-053",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    name: "Colour Game",
    name: "Colour Game",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-050",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    name: "Horse Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-048",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    name: "KM Virtual Greyhound Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-055",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-058",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-8.webp",
    name: "5 Card Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-14.webp",
    name: "Plinko",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-040",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    name: "Bonus Dice",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-043",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-15.webp",
    name: "keno",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-018",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    name: "Monkey King Roulette",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-045",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    name: "sicbo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-015",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-4.webp",
    name:"7 Up 7 Down",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-028",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-6.webp",
    name:"Coin Toss",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    name: "Cards Hi Lo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-037",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-13.webp",
    name: "32 Cards",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    name: "Card Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-021.webp",
    name: "Number Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-021",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-032.webp",
    name: "Andar Bahar",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-032",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-038.webp",
    name: "Blackjack",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-038",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-042.webp",
    name: "Minesweeper",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-042",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-035.webp",
    name: "Bola Golek",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-035",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-030.webp",
    name: "jhandimunda",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-030",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  // Table End KINGMAKER (casino Done) -------


  // Table Start JILI (casino Done) -------
  {
    img: "../assets/images/JILI/JILI-TABLE-021.webp",
    name: "Ludo Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-014.webp",
    name: "Baccarat",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-017.webp",
    name: "Sic Bo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-016.webp",
    name: "TeenPatti 20-20",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-018.webp",
    name: "Super Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-015.webp",
    name: "Fortune Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-011.webp",
    name: "7up7down",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-013.webp",
    name: "Callbreak Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-012.webp",
    name: "Dragon & Tiger",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-010.webp",
    name: "TeenPatti Joker",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-008.webp",
    name: "iRich Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-009.webp",
    name: "Callbreak",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-003.webp",
    name: "Andar Bahar",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-004.webp",
    name: "Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-005.webp",
    name: "NumberKing",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-027.webp",
    name: "Golden Land",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-033.webp",
    name: "Mines",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-006.webp",
    name: "Poker King",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-007.webp",
    name: "Big small",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-028.webp",
    name: "PAPPU",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-035.webp",
    name: "Limbo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-035",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-036.webp",
    name: "wheel",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-039.webp",
    name: "Color Prediction",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-032.webp",
    name: "HILO",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-030.webp",
    name: "Go Rush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-031.webp",
    name: "Tower",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-002.webp",
    name: "AK47",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
    cateogry: "JILI",
    type: "TABLE",
  },
 
  // Table End JILI (casino Done) -------

  // Other 

  {
    img: "../assets/images/Others/MX-LIVE-015_SEXY.webp",
    name: "Thai Fish Prawn Crab",
    casinoType: "MX-LIVE-015",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    cateogry: "SEXY",
    type: "OTHERS",
  },
  {
    img: "../assets/images/Others/VN-LIVE-008.webp",
    name: "Thai Fish Prawn Crab",
    casinoType: "VN-LIVE-008",
    platForm: "VENUS",
    gameType: "LIVE",
    cateogry: "VENUS",
    type: "OTHERS",
  },
  // Other
  // Slot Start PP (casino Done) -----
  {
    img: "../assets/images/slot/PP-SLOT-002.webp",
    name: "Gates of Olympus",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-082.webp",
    name: "Sweet Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-189.webp",
    name: "Starlight Princess",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-189",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-183.webp",
    name: "5 Lions Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-183",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-080.webp",
    name: "5 Lions Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-080",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-049.webp",
    name: "Great Rhino Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-049",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-069.webp",
    name: "Fire Strike",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-069",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-067.webp",
    name: "Sweet Bonanza Xmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-067",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-015.webp",
    name: "Bonanza Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-015",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-176.webp",
    name: "Buffalo King Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-176",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-181.webp",
    name: "The Magic Cauldron - Enchanted Brew",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-181",
    cateogry: "PP",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-046.webp",
    name: "Fruit Party",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-046",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-187.webp",
    name: "Pyramid Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-187",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-204.webp",
    name: "Candy Village",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-204",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-126.webp",
    name: "3 Kingdoms - Battle of Red Cliffs",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-126",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-104.webp",
    name: "5 Lions",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-104",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-072.webp",
    name: "John Hunter and the Tomb of the Scarab Queen",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-072",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-107.webp",
    name: "Joker's Jewels",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-107",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-025.webp",
    name: "Gems Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-025",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-210.webp",
    name: "Santa's Wonderland",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-210",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-233.webp",
    name: "Wild West Gold Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-233",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-234.webp",
    name: "Spirit of Adventure",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-234",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-235.webp",
    name: "Fire Strike 2",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-235",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-236.webp",
    name: "Barn Festival",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-236",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-237.webp",
    name: "Chicken Chase",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-237",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-238.webp",
    name: "Goblin Heist Powernudge",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-238",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-239.webp",
    name: "Eye of Cleopatra",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-239",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-240.webp",
    name: "The Great Stick-Up",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-240",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-296.webp",
    name: "Aztec Blaze",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-296",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-297.webp",
    name: "Starlight Christmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-297",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-298.webp",
    name: "Towering Fortunes",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-298",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-299.webp",
    name: "Firebird Spirit",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-299",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-300.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-300",
    cateogry: "PP",
    type: "SLOT",
  },
  // Slot End PP (casino Done) -----

  // Slot Start KINGMAKER (casino Done) -----
  {
    img: "../assets/images/slot/KM-SLOT-001.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-001",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  // Slot End KINGMAKER (casino Done) -----

  // Slot Start DRAGOONSOFT (casino Done) -----
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
    name: "777",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-003",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
    name: "Get Money",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-004",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
    name: "Over Dragon's Gate",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-012",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
    name: "Phoenix",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-002",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
    name: "Diamond Mogul",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-011",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
    name: "Rich Now",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-005",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
    name: "Dracula",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-008",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
    name: "Bust Treasury",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-020",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
    name: "Coin Cat",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-001",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
    name: "Egypt Oracle",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-015",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-018.webp",
    name: "Rich Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-018",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-017.webp",
    name: "Candy Dynasty",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-017",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-007.webp",
    name: "Doggy Wealth",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-007",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-016.webp",
    name: "Caishen Coming",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-016",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-006.webp",
    name: "Chef Lady",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-006",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-009.webp",
    name: "Maya King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-009",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-010.webp",
    name: "Pirate King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-010",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-013.webp",
    name: "Great Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-013",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-014.webp",
    name: "Ultra Treasure",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-014",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-019.webp",
    name: "Monkey King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-019",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  // Slot End DRAGOONSOFT (casino Done) -----

  // Slot Start SPADE (casino Done) -----
  // {
  //   img: "../assets/images/slot/SG-SLOT-106.webp",
  //   name: "Fiery Sevens Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-106",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-074.webp",
  //   name: "Mega7",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-074",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-116.webp",
  //   name: "Legacy Of Kong Maxways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-116",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-069.webp",
  //   name: "888",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-069",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-112.webp",
  //   name: "Fruits Mania",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-112",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-076.webp",
  //   name: "DoubleFlame",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-076",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-110.webp",
  //   name: "Space Conquest",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-110",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-081.webp",
  //   name: "Triple Panda",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-081",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-077.webp",
  //   name: "Book of Myth",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-077",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-095.webp",
  //   name: "Poker Ways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-095",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },





  // {
  //   img: "../assets/images/slot/SG-SLOT-086.webp",
  //   name: "Mayan Gems",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-086",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-075.webp",
  //   name: "JokersTreasure",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-075",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-030.webp",
  //   name: "5FortuneSA",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-030",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-103.webp",
  //   name: "Sexy Vegas",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-103",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-107.webp",
  //   name: "Lucky Koi Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-107",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-082.webp",
  //   name: "Gold Panther",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-082",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-032.webp",
  //   name: "Golden Lotus SE",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-032",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-105.webp",
  //   name: "Royale House",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-105",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // Slot End SPADE (casino Done) -----

  // Slot Start PT (casino Done) -----
  {
    img: "../assets/images/slot/PT-SLOT-082.webp",
    name: "Football Rules",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-082",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-070.webp",
    name: "Heart of the Frontier",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-070",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-071.webp",
    name: "Thai Paradise",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-071",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-072.webp",
    name: "Legacy of the Wild",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-072",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-073.webp",
    name: "Archer",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-073",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-001.webp",
    name: "Triple Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-001",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-003.webp",
    name: "Buffalo Blitz",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-003",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-006.webp",
    name: "Great Blue",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-006",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-004.webp",
    name: "Captain Treasure",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-004",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-005.webp",
    name: "Long Long Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-005",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-009.webp",
    name: "Golden Tour",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-009",
    cateogry: "PT",
    type: "SLOT",
  },
  
  {
    img: "../assets/images/slot/PT-SLOT-010.webp",
    name: "Funky Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-010",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-011.webp",
    name: "Highway Kings",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-011",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-014.webp",
    name: "Zhao Cai Tong Zi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-014",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-012.webp",
    name: "Zhao Cai Jin Bao",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-012",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-013.webp",
    name: "Jin Qian Wa",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-013",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-015.webp",
    name: "Ugga Bugga",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-015",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-016.webp",
    name: "Safari Heat",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-016",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-002.webp",
    name: "Arowanas Luck",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-002",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-019.webp",
    name: "Gem Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-019",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-023.webp",
    name: "Chaoji 888",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-023",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-008.webp",
    name: "Blue Wizard",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-008",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-018.webp",
    name: "Bonus Bears",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-018",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-017.webp",
    name: "Buffalo Blitz II",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-017",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-007.webp",
    name: "Sky Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-007",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-020.webp",
    name: "Feng Kuang Ma Jiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-020",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-021.webp",
    name: "Epic Ape",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-021",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-022.webp",
    name: "Ni Shu Shen Me",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-022",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-024.webp",
    name: "Vacation Station Deluxe",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-024",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-025.webp",
    name: "Eternal Lady",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-025",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-026.webp",
    name: "Golden Macaque",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-026",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-027.webp",
    name: "Fei Long Zai Tian",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-027",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-028.webp",
    name: "Yun Cong Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-028",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-029.webp",
    name: "Jinfu Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-029",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-030.webp",
    name: "Hot Gems",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-030",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-031.webp",
    name: "Lie Yan Zuan Shi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-031",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-032.webp",
    name: "Dolphin Reef",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-032",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-033.webp",
    name: "Jinns Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-033",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-034.webp",
    name: "Tiger, Turtle, Dragon, Phoenix",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-034",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-035.webp",
    name: "Heart of the Jungle",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-035",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-036.webp",
    name: "Heavenly Ruler",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-036",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-037.webp",
    name: "Ox Riches",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-037",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-038.webp",
    name: "Ice Cave",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-038",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-039.webp",
    name: "Queen of Wands",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-039",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-040.webp",
    name: "Si Xiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-040",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-041.webp",
    name: "Panther Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-041",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-043.webp",
    name: "Asian Fantasy",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-043",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-044.webp",
    name: "Fortune Lions",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-044",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-045.webp",
    name: "Hot KTV",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-045",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-046.webp",
    name: "Fortune Day",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-046",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-047.webp",
    name: "Ri Ri Jin Cai",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-047",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-048.webp",
    name: "Sun WuKong",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-048",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-049.webp",
    name: "Ji Xiang 8",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-049",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-050.webp",
    name: "Jade Emperor Yu Huang Da Di",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-050",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-051.webp",
    name: "Halloween Fortune 2",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-051",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-060.webp",
    name: "Chinese Kitchen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-060",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-061.webp",
    name: "Geisha Story",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-061",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-062.webp",
    name: "Fortunes of the Fox",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-062",
    cateogry: "PT",
    type: "SLOT",
  },
  // Slot End PT (casino Done) -----

  // Slot Start JDB (casino Done) -----
  {
    img: "../assets/images/slot/JDB-SLOT-108.webp",
    name: "Moneybags Man 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-108",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-106.webp",
    name: "FortuneNeko",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-106",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-107.webp",
    name: "Trump Card",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-107",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-105.webp",
    name: "BookOfMystery",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-105",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-001.webp",
    name: "Burglar",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-001",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-003.webp",
    name: "Chef Doeuvre",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-003",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-004.webp",
    name: "Lucky Miner",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-004",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-005.webp",
    name: "Candy Land",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-005",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-006.webp",
    name: "Crazy Scientist",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-006",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-007.webp",
    name: "Super Dumpling",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-007",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-008.webp",
    name: "Cash Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-008",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-009.webp",
    name: "Wild Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-010.webp",
    name: "Flirting Scholar Tang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-011.webp",
    name: "Winning Mask",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-012.webp",
    name: "Wu kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-013.webp",
    name: "Llama Adventure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-014.webp",
    name: "Formosa Bear",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-015.webp",
    name: "Lucky Qilin",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-016.webp",
    name: "New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-017.webp",
    name: "Four Treasures",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-018.webp",
    name: "Open Sesame",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-019.webp",
    name: "Banana Saga",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-019",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-020.webp",
    name: "Olympian Temple",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-020",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-021.webp",
    name: "Dancing Pa Pa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-021",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-022.webp",
    name: "Dragon King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-022",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-023.webp",
    name: "Magic Show",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-023",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-024.webp",
    name: "Cock Fight",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-024",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-025.webp",
    name: "Dragon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-025",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-026.webp",
    name: "Rooster In Love",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-026",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-027.webp",
    name: "Monkey King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-027",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-028.webp",
    name: "Moonlight Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-028",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-029.webp",
    name: "Mahjong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-029",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-030.webp",
    name: "Guan Gong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-030",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-031.webp",
    name: "Billionaire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-031",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-032.webp",
    name: "Dragon Warrior",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-032",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-033.webp",
    name: "Kingsman",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-033",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-034.webp",
    name: "Curvy Magician",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-034",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-035.webp",
    name: "RollingIn Money",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-035",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-036.webp",
    name: "Chef Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-036",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-045.webp",
    name: "Lucky Dragons",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-045",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-046.webp",
    name: "Lucky Lion",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-046",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-047.webp",
    name: "Coffeeholics",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-047",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-048.webp",
    name: "Napoleon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-048",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-049.webp",
    name: "Lucky Phoenix",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-049",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-050.webp",
    name: "WinningMask II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-050",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-051.webp",
    name: "Open Sesame II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-051",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-052.webp",
    name: "Flirting Scholar Tang II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-052",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-053.webp",
    name: "Fortune Horse",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-053",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-054.webp",
    name: "Xi Yang Yang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-054",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-055.webp",
    name: "Panda Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-055",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-056.webp",
    name: "Zelda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-056",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-057.webp",
    name: "Mr Bao",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-057",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-058.webp",
    name: "One Punch Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-058",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-059.webp",
    name: "Guardians of The Galaxy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-059",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-060.webp",
    name: "Street Fighter",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-060",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-061.webp",
    name: "Star Wars",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-061",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-062.webp",
    name: "War of Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-062",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-063.webp",
    name: "Daji",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-063",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-064.webp",
    name: "Poker King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-064",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-065.webp",
    name: "Lucky Racing",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-065",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-066.webp",
    name: "Birds Party",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-066",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-067.webp",
    name: "Go Lai Fu",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-067",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-068.webp",
    name: "Gems Gems",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-068",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-069.webp",
    name: "Dragons World",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-069",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-070.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-070",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-071.webp",
    name: "Egypt Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-071",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-072.webp",
    name: "Fortune Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-072",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-073.webp",
    name: "Pirate Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-073",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-074.webp",
    name: "Mjoinir",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-074",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-075.webp",
    name: "Treasure Bowl",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-075",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-076.webp",
    name: "Funky King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-076",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-077.webp",
    name: "Super Niubi Deluxe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-077",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-078.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-078",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-079.webp",
    name: "Lucky Fuwa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-079",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-080.webp",
    name: "Inca Empire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-080",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-081.webp",
    name: "Ninja Rush",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-081",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-082.webp",
    name: "Sun Archer",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-082",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-083.webp",
    name: "Mystery of Nine Tales",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-083",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-084.webp",
    name: "Golden Disco",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-084",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-085.webp",
    name: "Miner Babe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-085",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-086.webp",
    name: "Moneybags Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-086",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-087.webp",
    name: "Double Wilds",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-087",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-088.webp",
    name: "Pop Pop Fruity",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-088",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-089.webp",
    name: "Spindrift",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-089",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-090.webp",
    name: "Spindrift 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-090",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-091.webp",
    name: "Jungle Jungle",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-091",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-092.webp",
    name: "Dragons Gate",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-092",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-093.webp",
    name: "Lucky Diamond",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-094.webp",
    name: "Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-094",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-095.webp",
    name: "NinjaX",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-095",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-096.webp",
    name: "Rex Brothers",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-096",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-097.webp",
    name: "Wonder Elephant",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-097",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-098.webp",
    name: "MarvelousIV",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-098",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-099.webp",
    name: "Maya Gold Crazy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-099",
    cateogry: "JDB",
    type: "SLOT",
  },
  // Slot End JDB (casino Done) -----

  // Slot Start FC (casino Done) -----

  // {
  //   img: "../assets/images/slot/FC-SLOT-025.webp",
  //   name: "SUGAR BANG BANG",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-025",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-021.webp",
  //   name: "Lucky Fortunes",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-021",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-020.webp",
  //   name: "Robin Hood",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-020",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-019.webp",
  //   name: "GLORY OF ROME",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-019",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-001.webp",
  //   name: "GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-001",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-002.webp",
  //   name: "THREE LITTLE PIGS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-002",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-003.webp",
  //   name: "HOT POT PARTY",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-003",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-004.webp",
  //   name: "NIGHT MARKET",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-004",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-005.webp",
  //   name: "PONG PONG HU",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-005",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-006.webp",
  //   name: "PANDA DRAGON BOAT",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-006",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-007.webp",
  //   name: "CHINESE NEW YEAR",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-007",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-008.webp",
  //   name: "FORTUNE KOI",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-008",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-009.webp",
  //   name: "HAPPY DUO BAO",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-009",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-010.webp",
  //   name: "	DA LE MEN",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-010",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-011.webp",
  //   name: "ANIMAL RACING",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-011",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-012.webp",
  //   name: "LUXURY GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-012",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-013.webp",
  //   name: "MAGIC BEANS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-013",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-014.webp",
  //   name: "COWBOYS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-014",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-015.webp",
  //   name: "WAR OF THE UNIVERSE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-015",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-016.webp",
  //   name: "TREASURE CRUISE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-016",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // Slot End FC (casino Done) -----

  // Slot Start JILI (casino Done) -----
   {
    img: "../assets/images/slot/JILI-SLOT-063.webp",
    name: "World Cup",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-056.webp",
    name: "Happy Taxi",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-056",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-057.webp",
    name: "Gold Rush",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-057",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-054.webp",
    name: "Crazy Pusher",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-054",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-053.webp",
    name: "Book of Gold",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-053",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-071.webp",
    name: "Neko Fortune",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-071",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-051.webp",
    name: "Mega Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-052.webp",
    name: "Thor X",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-052",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-002.webp",
    name: "Hot Chilli",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-003.webp",
    name: "Chin Shi Huang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-003",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-004.webp",
    name: "War Of Dragons",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-005.webp",
    name: "Fortune Tree",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-005",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-006.webp",
    name: "Lucky Ball",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-007.webp",
    name: "Hyper Burst",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-008.webp",
    name: "Shanghai Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-008",
    cateogry: "JILI",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JILI-SLOT-009.webp",
    name: "Fa Fa Fa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-009",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-010.webp",
    name: "God Of Martial",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-010",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-012.webp",
    name: "Hawaii Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-012",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-013.webp",
    name: "SevenSevenSeven",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-014.webp",
    name: "Crazy777",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-015.webp",
    name: "Bubble Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-016.webp",
    name: "Bao boon chin",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-016",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-017.webp",
    name: "Crazy FaFaFa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-018.webp",
    name: "XiYangYang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-018",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-019.webp",
    name: "FortunePig",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-019",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-020.webp",
    name: "Candy Baby",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-021.webp",
    name: "Diamond Party",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-021",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-022.webp",
    name: "Fengshen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-022",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-023.webp",
    name: "Golden Bank",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-024.webp",
    name: "Lucky Goldbricks",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-024",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-025.webp",
    name: "Dragon Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-025",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-026.webp",
    name: "Charge Buffalo",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-027.webp",
    name: "Super Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-028.webp",
    name: "Jungle King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-029.webp",
    name: "Money Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-030.webp",
    name: "Golden Queen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-031.webp",
    name: "Boxing King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-032.webp",
    name: "Matka India",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-032",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-037.webp",
    name: "Lucky Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-037",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-038.webp",
    name: "Pharaoh Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-038",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-039.webp",
    name: "Secret Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-039",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-040.webp",
    name: "RomaX",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-040",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-041.webp",
    name: "Super Rich",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-041",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-042.webp",
    name: "Golden Empire",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-042",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-043.webp",
    name: "Fortune Gems",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-044.webp",
    name: "Party Night",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-044",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-045.webp",
    name: "Crazy Hunter",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-046.webp",
    name: "Magic Lamp",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-047.webp",
    name: "TWINWINS",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-047",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-048.webp",
    name: "Agent Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-048",
    cateogry: "JILI",
    type: "SLOT",
  },
  // Slot End JILI (casino Done) -----

  // Fishing Start SPADE (casino Done) -----
  // {
  //   img: "../assets/images/Fishing/SG-FISH-001.webp",
  //   name:"Fishing God",
  //   platForm: "SPADE",
  //   gameType: "FH",
  //   casinoType: "SG-FISH-001",
  //   cateogry: "SPADE",
  //   type: "FISHING",
  // },
  // {
  //   img: "../assets/images/Fishing/SG-FISH-002.webp",
  //   name:"Fishing War",
  //   platForm: "SPADE",
  //   gameType: "FH",
  //   casinoType: "SG-FISH-002",
  //   cateogry: "SPADE",
  //   type: "FISHING",
  // },
  // {
  //   img: "../assets/images/Fishing/SG-FISH-003.webp",
  //   name: "Alien Hunter",
  //   platForm: "SPADE",
  //   gameType: "FH",
  //   casinoType: "SG-FISH-003",
  //   cateogry: "SPADE",
  //   type: "FISHING",
  // },
  // {
  //   img: "../assets/images/Fishing/SG-FISH-004.webp",
  //   name: "Zombie Party",
  //   platForm: "SPADE",
  //   gameType: "FH",
  //   casinoType: "SG-FISH-004",
  //   cateogry: "SPADE",
  //   type: "FISHING",
  // },
  // Fishing End SPADE (casino Done) -----

  // Fishing Start JILI (casino Done) -----
  {
    img: "../assets/images/Fishing/JILI-FISH-001.webp",
    name: "Royal Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-001",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-002.webp",
    name: "Bombing Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-002",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-003.webp",
    name: "Jackpot Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-003",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-004.webp",
    name: "Dinosaur Tycoon",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-004",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-005.webp",
    name: "Happy Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-005",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-006.webp",
    name: "Dragon Fortune",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-006",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-007.webp",
    name: "Mega Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-007",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-008.webp",
    name: "Boom Legend",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-008",
    cateogry: "JILI",
    type: "FISHING",
  },
  // Fishing End JILI (casino Done) -----

  // Egame Start JDB (casino Done) -----
  {
    img: "../assets/images/Egame/JDB-EGAME-002.webp",
    name:"Lucky Color Game",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-002",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-006.webp",
    name:"King Of Football",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-006",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-001.webp",
    name: "Crazy King Kong",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-001",
    cateogry: "JDB",
    type: "EGAME",
  },

  {
    img: "../assets/images/Egame/JDB-EGAME-007.webp",
    name:"Super Super Fruit",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-007",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-011.webp",
    name:"Jogo Do Bicho",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-011",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-010.webp",
    name:"Beer Tycoon",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-010",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-005.webp",
    name:"CAISHEN PARTY",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-005",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-003.webp",
    name:"Birds and Animals",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-003",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-009.webp",
    name:"Classic Mario",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-009",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-004.webp",
    name:"Happy New Year",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-004",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-008.webp",
    name:"Huaguoshan Legends",
    platForm: "JDB",
    gameType: "EGAME",
    casinoType: "JDB-EGAME-008",
    cateogry: "JDB",
    type: "EGAME",
  },
  // Egame End JDB (casino Done) -----

  // Egame Start PP (casino Done) -----
  {
    img: "../assets/images/Egame/PP-EGAME-004.webp",
    name:"Fantastic League",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-004",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-002.webp",
    name:"Penalty Shootout",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-002",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-005.webp",
    name:"Greyhound Racing",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-005",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-003.webp",
    name:"Horse Racing",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-003",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-006.webp",
    name:"Force 1",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-006",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-007.webp",
    name:"Darts",
    platForm: "PP",
    gameType: "EGAME",
    casinoType: "PP-EGAME-007",
    cateogry: "PP",
    type: "EGAME",
  },
  // Egame End PP (casino Done) -----
];